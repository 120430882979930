import { Profile } from 'model/User'
import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import {
  SendActivationEmailRequest,
  UserDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { IUserAPI } from './PartyAccessController'

export class UserAPI extends InternalAPI implements IUserAPI {
  constructor(private onRequestChange: OnRequestChangeCallback) {
    super('/api/user', onRequestChange)
  }

  public async GetProfile() {
    return await this.GetAuthenticatedAsync<UserDto>({
      relativePath: '',
      id: 'get-profile',
    })
  }

  public async SaveProfile(profile: Profile) {
    return await this.PutAsync<UserDto>({
      relativePath: '',
      data: profile,
      id: 'save-profile',
    })
  }

  public async SendActivationEmail(req: SendActivationEmailRequest) {
    return await this.PostAsync<void>({
      id: 'send-activation-email',
      relativePath: '/SendActivationEmail',
      data: req,
    })
  }
}
