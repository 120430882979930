import { Auth0ContextInterface } from '@auth0/auth0-react'
import { ClearLoginTries } from 'features/Auth/useAppLogin'
import { Navigate } from 'react-router-dom'

export class AuthService {
  private static _instance: AuthService

  private constructor(private auth0Context: Auth0ContextInterface) {}

  public static Build(auth0Context: Auth0ContextInterface) {
    if (!this._instance) {
      try {
        this._instance = new AuthService(auth0Context)
      } catch (err) {
        console.error('unable to create Auth0Service', err)
      }
    }
  }

  public static GetCurrent() {
    if (!this._instance) {
      console.info('AuthContext was not build.')
      return null
    }

    return this._instance
  }

  public GetAuth0User() {
    return this.auth0Context.user
  }

  public async Logout() {
    Navigate({
      to: '/logout',
      state: {
        returnUrl: window.location.origin,
      },
    })
  }

  private async redirectToLoginPage() {
    window['login_error_handled'] = true
    ClearLoginTries()

    const redirectUrl =
      window.location.pathname + window.location.search + window.location.hash

    console.warn('AuthService: redirecting to login page with redirectUrl', {
      redirectUrl,
    })

    window.location.replace('/login?returnTo=' + redirectUrl)
  }

  public async  GetAccessToken() {
    if (!this.auth0Context.isAuthenticated) {
      this.redirectToLoginPage()
      return null
    }

    try {
      let token = ''

      token = await this.auth0Context.getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://api.rhodium24.io',
        },
      })

      return token
    } catch (err: unknown) {
      console.error('unable to get access token', err, err.toString())
      if (
        err.toString().toLowerCase().includes('login') ||
        err['error'] === 'login_required' ||
        err['error'] === 'consent_required'
      ) {
        console.warn('AuthService: login expired, redirecting to login page')
        this.redirectToLoginPage()
      } else {
        window.location.replace('/error')
      }
      console.error(err)
      return undefined
    }
  }
}
