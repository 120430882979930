export type Currency = {
  symbol: string
  name: string
  code: string
}

export const CurrenciesOptions: Record<string, Currency> = {
  EUR: {
    symbol: '€',
    name: 'Euro',
    code: 'EUR',
  },
  GBP: {
    symbol: '£',
    name: 'British Pound',
    code: 'GBP',
  },
  USD: {
    symbol: '$',
    name: 'United States Dollar',
    code: 'USD',
  },
  CAD: {
    symbol: 'C$',
    name: 'Canadian Dollar',
    code: 'CAD',
  },
  AUD: {
    symbol: 'A$',
    name: 'Australian Dollar',
    code: 'AUD',
  },
  CHF: {
    symbol: 'Fr.',
    name: 'Swiss franc',
    code: 'CHF',
  },
  BRL: {
    symbol: 'R$',
    name: 'Brazilian real',
    code: 'BRL',
  },
  DKK: {
    symbol: 'kr.',
    name: 'Danish krone',
    code: 'DKK',
  },
  CNY: {
    symbol: '元',
    name: 'Chinese Renminbi',
    code: 'CNY',
  },
  PLN: {
    symbol: 'zł',
    name: 'Polish złoty',
    code: 'PLN',
  },
  JPY: {
    symbol: '円',
    name: 'Japanese yen',
    code: 'JPY',
  },
  EGP: {
    symbol: 'E£',
    name: 'Egyptian pound',
    code: 'EGP',
  }
  
}
